.footer {
  background-color: rgba(var(--light-rgb), 0.05);
  padding: 15px;
  position: fixed;
  bottom: 0;
  z-index: 9;

  div {
    display: flex;
    flex-direction: row;
  }

  a {
    padding-top: 5px;
    margin: 0px 15px;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: 0.25s ease;
    transform: skew(4deg);

    i,
    img {
      color: var(--secondary);
      font-size: 22px;
      filter: drop-shadow(5px 5px 10px var(--dark));
      cursor: pointer;
    }

    img {
      padding-top: 5px;
    }

    &:hover {
      background-color: var(--primary);
    }
  }

  @media screen and (max-width: 992px) {
    position: fixed;
    bottom: 0;
    background-color: rgba(var(--dark-rgb), 0.75);
  }
}

.mint-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 60px;
  background: rgba(var(--dark-rgb), 0.9);
  box-shadow: 0px 0px 30px 10px rgba(var(--dark-rgb), 0.5);
  border-radius: 10px;
  transform: skew(4deg);
  transition: 0.25s ease;

  &:hover {
    transform: skew(4deg) scale(1.025);
  }

  @media screen and (max-width: 380px) {
    padding: 30px !important;
  }

  @media screen and (max-width: 992px) {
    transform: skew(0deg);

    .content {
      transform: skew(0deg) !important;
    }
  }

  .content {
    transform: skew(-4deg);
    align-self: center;
  }
}

.img-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10vmin;
  overflow: hidden;
  transform: skew(4deg);

  .card {
    flex: 1;
    transition: 0.5s ease-in-out;
    height: 70vh;
    border: 2px solid var(--dark);
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    &:hover {
      flex-grow: 4;

      img {
        filter: grayscale(0);
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.5s ease-in-out;
      border-radius: 10px;
      transform-origin: top;
      transform: skew(-4deg) scale(1.3) translateX(8%);
      filter: grayscale(100%);
    }

    &:not(:nth-child(5)) {
      margin-right: 1em;
    }
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
}
